<template>
    <div>
        <div class="">

            <div class="d-flex justify-content-center my-4">
                <div class="w-75" style="background-color: lightgrey; padding: 35px;">
                    <h3>About game</h3>
                    <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Veniam qui tempore ratione. Ut beatae totam reiciendis dolorem nostrum! Alias pariatur eum sapiente nihil qui eius aperiam libero placeat earum sequi!</p>
                </div>
            </div>

            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="accordion" role="tablist">
                            <b-card no-body class="mb-3">
                                <b-card-header header-tag="header" role="tab">
                                    <a style="display:flex; justify-content: space-between; color:black; text-decoration: none;" href="javascript:void(0);"  v-b-toggle.accordion1>Getting started <i class='mdi mdi-arrow-down'></i></a>
                                </b-card-header> 
                                <b-collapse class="anim-effect" id="accordion1"  accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <div class="row">
                                    <div class="col-md-12">
                                        <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, ex at ratione vero autem aspernatur, nam quae molestias omnis nihil quidem cupiditate ab facere nobis tempore soluta, earum eum necessitatibus?
                                        </p>
                                    </div>
                                    </div>
                                </b-card-body>
                                </b-collapse> 
                            </b-card>

                            <b-card no-body class="mb-3">
                                <b-card-header header-tag="header" role="tab">
                                    <a style="display:flex; justify-content: space-between; color:black; text-decoration: none;" href="javascript:void(0);"  v-b-toggle.accordion2>Basics <i class='mdi mdi-arrow-down'></i></a>
                                </b-card-header> 
                                <b-collapse class="anim-effect" id="accordion2"  accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <div class="row">
                                    <div class="col-md-12">
                                        <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis, ex at ratione vero autem aspernatur, nam quae molestias omnis nihil quidem cupiditate ab facere nobis tempore soluta, earum eum necessitatibus?
                                        </p>
                                    </div>
                                    </div>
                                </b-card-body>
                                </b-collapse> 
                            </b-card>
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="accordion" role="tablist">
                            <b-card no-body class="mb-3">
                                <b-card-header header-tag="header" role="tab">
                                    <a style="display:flex; justify-content: space-between; color:black; text-decoration: none;" href="javascript:void(0);"  v-b-toggle.accordion10>Managing your parent account <i class='mdi mdi-arrow-down'></i></a>
                                </b-card-header> 
                                <b-collapse class="anim-effect" id="accordion10"  accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <div class="row">
                                    <div class="col-md-12">
                                        <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci quo officiis alias molestias cumque aperiam asperiores nisi doloribus perferendis. Maiores officiis nam sapiente eum hic facere debitis expedita quas ab.
                                        </p>
                                    </div>
                                    </div>
                                </b-card-body>
                                </b-collapse> 
                            </b-card>

                            <b-card no-body class="mb-3">
                                <b-card-header header-tag="header" role="tab">
                                    <a style="display:flex; justify-content: space-between; color:black; text-decoration: none;" href="javascript:void(0);"  v-b-toggle.accordion11>Managing your child’s account <i class='mdi mdi-arrow-down'></i></a>
                                </b-card-header> 
                                <b-collapse class="anim-effect" id="accordion11"  accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <div class="row">
                                    <div class="col-md-12">
                                        <p>
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci quo officiis alias molestias cumque aperiam asperiores nisi doloribus perferendis. Maiores officiis nam sapiente eum hic facere debitis expedita quas ab.
                                        </p>
                                    </div>
                                    </div>
                                </b-card-body>
                                </b-collapse> 
                            </b-card>
                        </div>
                    </div>
                </div>
            </div>

            


        </div>
    </div>
</template>

<script>
// import axios from "axios"
import router from "@/router"
var rldata = {
  user: {
    _id: '',
    childs: []
  }
}
export default {
  name: "Help",
  data () {
    return {
      rldata
    }
  },
  methods: {
  },
  mounted () {
    try{
        console.log('here');
        console.log(this.rldata);
        this.$root.getUserData(this.rldata);
        console.log(this.rldata);
      } catch(err){
        this.$root.logout();
      }
  },
   watch: {
     "rldata.user": function(handler){
       if(handler != null) {

        // switch(rldata.user.gr){
        //   case "GR_PARENT":
        //     if(rldata.user.childs.length == 0){
        //       router.push("/parents/child_connect");
        //     }
        //     break;

        //   default:
        //   console.log('none');
        // }

       }
     }
   }
}
</script>